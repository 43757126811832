.rotate {
  transform: rotate(180deg);
}

.userInfoContainer {
  border-left: 1px solid var(--main-color-secondary);
}

.arrow {
  color: #D9D9D9;
}
