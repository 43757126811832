.tag {
  display: flex;
  flex-wrap: wrap;
}

.tagBlock {
  display: flex;
  margin-right: 4px;
}

.tagValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3438E0;
}

.tagKey {
  composes: tagValue;
  font-style: normal;
}

.commonTagStyle {
  display: flex;
  padding: 5px 12px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 24px;
}

.alignedTag {
  margin-bottom: 8px;
  border: 1px solid #A3B7FF;
  background: #EDF1FF;
  color: #3438E0;
}

.tagButton {
  margin-bottom: 8px;
  border: 1px solid #D1D6DD;
  background: #F7F8F9;
  color: #101928;
  cursor: pointer;
}

.tagButton:hover {
  border: 1px solid #A3B7FF;
  background: #EDF1FF;
  color: #3438E0;
}
