.blue {
  background-color: #EDF3FF;
}

.grey {
  background-color: #F7F7F9;
}

.orange {
  background-color: #FFF5E2;
}

.icon {
  width: 18px;
  height: 18px;
}

.text {
  line-height: 18px;
}
