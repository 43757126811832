.options_block {
  max-height: 28.9rem;
  overflow: auto;
  gap: .3rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

/* Card */
.card {
  /* gap: 1rem; */
  user-select: none; 
  -webkit-user-select: none;
  border: 1px solid #1E4BD2;
  border-radius: 12px;
  width: 100%;
}

.card.selected {
  border-radius: 12px;
  outline: 2px solid #0A84FF;
  outline-offset: -2px;
}

/* > Card header */
.card .header {
  gap: 1rem;
  align-self: stretch; 
}

.card .header>.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Layout */
  width: 44px;
  height: 44px;

  /* Style */
  border-radius: 50px;
  background: #F3F4F6;
}
