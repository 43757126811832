.tableWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.table {
  flex: 1;
  overflow-y: auto;
}

.table thead[class*="ant-table-thead"] th,
.table td[class*="ant-table-cell-fix-right-first"] {
  text-wrap: wrap;
}

.table thead[class*="ant-table-thead"] th,
.table td[class*="ant-table-cell"],
.table td[class*="ant-table-cell-fix-right"] {
  font-weight: 400;
  padding: .85rem .85rem;
  line-height: 18px;
}

.table td[class*="ant-table-cell"],
.table td[class*="ant-table-cell-fix-right"] {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td[class*="ant-table-cell"] {
  padding: .75rem .75rem;
}

td[class*="ant-table-cell"] + .cellPhoto {
  padding: 0 !important;
}

.cellPhoto > .photoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 44px;
  height: 44px;
}

.photo {
  min-width: 22px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #FBFBFC;
  opacity: 1;
  overflow: hidden;
  margin-right: 10px;
}

.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon {
  width: 24px;
  height: 24px;
  color: #0A84FF;
}

.icon.error {
  color: #FF3B30;
}

.wrap { text-wrap: wrap !important; }
.nowrap { text-wrap: nowrap; }
