.count {
  margin-left: 4px;
  color: #374151;
  background-color: #67768C1A;
  padding: 0 8px;
  border-radius: 10px;
}

.placeholder {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0%;
  text-align: center;  
}

.deleteIcon {
  top: 5%;
  right: 5%;
}

.errorMsg {
  margin-top: 10px;
  color: #FF6347;
}

.filelistWrap {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.borderError {
  border: 1.2px dashed #FF6347;
}

.borderNormal {
  border: 1.2px dashed #d9d9d9;
}

@media (min-width: 2600px) {
  .filelistWrap {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

@media (max-width: 1280px) {
  .filelistWrap {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
