.commonTagStyle {
  display: flex;
  margin-right: 0;
  padding: 5px 12px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 24px;
}

.quickFilters {
  border: 1px solid #D1D6DD;
  background: #F7F8F9;
  cursor: pointer;
}

.quickFilters:hover {
  border: 1px solid #A3B7FF;
  background: #EDF1FF;
  color: #3438E0;
}

.activeFilters {
  border: 1px solid #A3B7FF;
  background: #EDF1FF;
  color: #3438E0;
  cursor: pointer;
}
