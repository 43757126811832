.modal_wrapper {
  max-height: 720px;
}

.infoText {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
}

.body {
  overflow-y: auto;
  background-color: #f4f5f7;
  border-radius: 8px;
  max-height: 556px;
}

.body.kbo {
  max-height: 504px;
}
.body.eula {
  max-height: 488px;
}
.body.doc {
  max-height: 556px;
}
