.modifications_wrapper .options_block {
  overflow: auto;
  gap: 0.3rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.modifications_wrapper {
  box-sizing: border-box;
}

.modifications_wrapper {
  .modificationCard_wrapper,
  .ant-skeleton {
    display: flex;
    max-height: 70px !important;
  }

  .ant-skeleton-content {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .ant-skeleton-title,
    .ant-skeleton-paragraph {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}
