.icon {
  width: 22px;
  height: 22px;
  color: #8591A3;
}

.red {
  color: red;
}

.infoCard {
  padding: 8px 15px;
}
