.noImage {
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 24px;
  height: 24px;
  align-content: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  overflow: hidden;
  border-radius: 50px;
  background: #F3F4F6;
}
