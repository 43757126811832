.left_sidebar {
  display: flex;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  overflow: auto;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.left_sidebar .sidebar_logo {
  color: var(--main-color);
  padding-left: 1rem;
  min-height: 4rem;
  width: 80%;
}

.left_sidebar>:first-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left_sidebar .sidebar_menu {
  display: flex;
  flex-direction: column;
  border-inline-end: none;
  border: none;
  border-radius: 20px;
  padding: 15px 0;
  margin-left: 40px;
  margin-top: 32px;
  min-width: 0;
  color: var(--main-color);
}

.left_sidebar .sidebar_menu .menu_item {
  color: var(--main-font-color);
  margin-right: 0;
  padding: 0;
}

.left_sidebar .sidebar_menu .menu_icon {
  color: var(--main-gray-color);
  width: 24px;
  height: 24px;
}

.left_sidebar .sidebar_menu .menu_item:hover .menu_icon {
  color: #4786FF;
}

.left_sidebar .sidebar_menu .menu_item.selected .menu_icon,
.left_sidebar .sidebar_menu .menu_item.selected,
.left_sidebar .sidebar_menu .menu_item.selected:hover {
  color: var(--main-color);
}
