.title_text {
  &.normal {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  &.large {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  &.x-large {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
}

.description_text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  gap: 0.3rem;
}
