@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .mx-w-420px {
    max-width: 420px;
    min-width: 420px;
  }

  .w-24px {
    width: 24px;
  }

  .w-7rem {
    width: 7rem;
  }

  .w-8rem {
    width: 8rem;
  }

  .h-100vh {
    height: 100vh;
  }

  .h-95vh {
    height: 95vh;
  }

  .h-90vh {
    height: 90vh;
  }

  .min-h-80 {
    min-height: 80%;
  }

  .left-4rem {
    left: 4.2rem;
  }

  .right-11rem {
    left: 11.5rem;
  }

  .top-1rem {
    top: 1.1rem;
  }

  .mt-auto {
    margin: auto 0 0 0;
  }

  .border-gray {
    border: 1px solid #67768C33;
  }

  .border-lightgray {
    border: 1px solid #f0f0f0;
  }

  .border-r-gray {
    border-right: 1px solid lightgray;
  }

  .border-b-gray {
    border-bottom: 1px solid rgba(103, 118, 140, 0.20);
  }

  .menu-divider {
    border-top: 1px solid lightgray;
    width: 90%;
    margin-left: 8px;
    background-color: #67768C33;
  }

  .card-radius {
    border-radius: 0.75rem;
  }

  .input-height {
    height: 44px;
  }

  .bg-main {
    background-color: #67768C0D;
  }

  .main-color {
    color: #101928;
  }

  .light-gray-color {
    color: #B3BAC5;
  }

  .bg-grey {
    background-color: #67768C26;
  }

  .btn-disabled {
    background-color: #67768C26;
    color: #67768C80;
  }

  .secondary-color {
    color: #67768C;
  }

  .warning-color {
    color: #FAAD14;
  }

  .danger-color {
    color: #FF4D4F;
  }

  .blue-color {
    color: #1E4BD2;
  }

  .main-font-color {
    color: #202A3A;
  }

  .ghost {
    color: #67768c;
  }

  .bg-blue {
    background-color: #1E4BD2;
  }

  .blue-ghost-bg {
    background-color: #EDF1FF;
  }

  h1,
  .text-h1-b {
    font-size: 38px;
    font-weight: 700;
  }

  .text-h1-m {
    font-size: 38px;
    font-weight: 500;
  }

  h2,
  .text-h2-b {
    font-size: 30px;
    font-weight: 700;
  }

  .text-h2-m {
    font-size: 30px;
    font-weight: 500;
  }

  h3,
  .text-h3-b {
    font-size: 24px;
    font-weight: 700;
  }

  .text-h3-m {
    font-size: 24px;
    font-weight: 500;
  }

  h4,
  .text-h4-b {
    font-size: 20px;
    font-weight: 700;
  }

  .text-h4-m {
    font-size: 20px;
    font-weight: 500;
  }

  h5,
  .text-h5-b {
    font-size: 18px;
    font-weight: 700;
  }

  .text-18-m {
    font-size: 18px;
    font-weight: 500;
  }

  .text-18-r {
    font-size: 18px;
    font-weight: 400;
  }

  .text-16-b {
    font-size: 16px;
    font-weight: 700;
  }

  .text-16-m {
    font-size: 16px;
    font-weight: 500;
  }

  .text-16-r {
    font-size: 16px;
    font-weight: 400;
  }

  .text-14-b {
    font-size: 14px;
    font-weight: 700;
  }

  .text-14-m {
    font-size: 14px;
    font-weight: 500;
  }

  .text-14-r {
    font-size: 14px;
    font-weight: 400;
  }

  .text-12-r {
    font-size: 12px;
    font-weight: 400;
  }
}

@media (max-width: 1400px) {
  h1,
  .text-h1-b {
    font-size: 34px;
    font-weight: 700;
  }

  .text-h1-m {
    font-size: 34px;
    font-weight: 500;
  }
}

@media (max-width: 1280px) {
  h1,
  .text-h1-b {
    font-size: 28px;
    font-weight: 700;
  }

  .text-h1-m {
    font-size: 28px;
    font-weight: 500;
  }

  .text-18-m {
    font-size: 14px;
    font-weight: 500;
  }

  .text-18-r {
    font-size: 14px;
    font-weight: 400;
  }
}

* {
  font-family: "HelveticaNeueCyr", Roboto, sans-serif;
}

:root {
  /* Colors */
  --main-color: #1E4BD2;
  --main-color-secondary: #4C92FB1A;
  --main-font-color: #202A3A;
  --main-gray-color: #B3BAC5;

  /* badge */
  --main-badge-color: var(--main-color);
  --main-badge-bg-color: #0A84FF1A;

  /* Menu item */
  /* basic */
  --menu-item-text: var(--main-font-color);
  --menu-item-text-hover: var(--main-font-color);
  /* selected */
  --menu-item-selected-text: var(--main-color);
  --menu-item-selected-text-hover: var(--main-font-color);
}

.ant-cascader-dropdown .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
  font-weight: 400 !important;
  color: var(--main-color);
}

.ant-cascader-dropdown .ant-cascader-menu-item.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-dropdown .ant-cascader-menu-item .ant-cascader-menu-item-loading-icon {
  color: inherit !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500 !important;
  color: rgba(30, 75, 210, 0.86);
}

.ant-tooltip .ant-tooltip-inner {
  background-color: #111827;
}

.ant-radio-wrapper {
  align-items: center;
}

.select-with-button .ant-select-single .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select-with-button .ant-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

body {
  margin: 0;
  height: 100%;
  color: #111827;
  box-sizing: border-box;
  font-family: "HelveticaNeueCyr", Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/*стили скроллбара для мозиллы*/
* {
  scrollbar-color: #8d8d8d rgba(141, 141, 141, 0.09);
  scrollbar-width: thin;
}

.dashboard-chart .recharts-surface {
  border-radius: 0.75rem;
}
